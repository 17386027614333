<template lang="pug">
    main#preco
        Banner(:contents="banner")
        PrecoDoDia
        PrecoRevista
</template>

<script>
import Banner from '@sections/Pagina/Banner/Banner'
import PrecoRevista from '@sections/Preco/PrecoRevista/PrecoRevista'
import PrecoDoDia from '@sections/Preco/PrecoDoDia/PrecoDoDia'
import pagina from '@/mixins/pagina'

export default {
    name: "view-preco-do-dia",
    mixins: [ pagina ],
    components: {
		Banner,
		PrecoDoDia,
		PrecoRevista,
	},
	data(){
		return {
			banner: {
				banner: {
					alt: `Preço do dia`,
					url: require(`@images/paginas/banner-administracao.jpg`),
				},
				title: `Preço do dia`
			},
		}
	},
    metaInfo() {
		return {
			title: 'Coamo',
			meta: [
				{ name: 'description', content: '' },
			],
		}
	},
}
</script>

<style lang="stylus" scoped src="./Preco.styl"></style>