<template lang="pug">
    main#PrecoRevista
        .wrapper
            .image
                img(src="@images/paginas/preco-do-dia/revistas.png")
            .text
                h4 REVISTA COAMO
                p Informe-se ainda mais com a Revista Coamo
                a(href='/pt-br/comunicacao/revistas/revista-coamo').squareButton ACESSAR TODAS
</template>

<script>

export default {
    name: "component-preco-revista",
    data() {
        return {
   
        }
    },
}
</script>

<style lang="stylus" scoped src="./PrecoRevista.styl"></style>